<!-- Loading bar -->
<!--<fuse-loading-bar></fuse-loading-bar>-->

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
 <!--   <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation"
        [opened]="false">
        &lt;!&ndash; Navigation header hook &ndash;&gt;
        <ng-container fuseVerticalNavigationContentHeader>
            &lt;!&ndash; Logo &ndash;&gt;
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>-->
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden shadow-xl">
        <div class="max-w-256 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl  sm:shadow-2xl overflow-hidden bg-card">
            <!-- Top bar -->
            <div class="sm:h-10 hidden sm:block"></div>
<!--            <div class="relative flex flex-auto flex-0 items-center justify-center h-12 px-4 md:px-6">-->
<!--                &lt;!&ndash; Logo &ndash;&gt;-->
<!--                <ng-container >-->
<!--                    <div class="flex items-center mx-2">-->
<!--                        &lt;!&ndash; Light version &ndash;&gt;-->
<!--                        <img-->
<!--                            class="h-12 dark:hidden"-->
<!--                            src="assets/images/logo/pink-logo.png"-->
<!--                            alt="Logo image">-->
<!--                        &lt;!&ndash; Dark version &ndash;&gt;-->
<!--                        <img-->
<!--                            class="hidden dark:flex h-12"-->
<!--                            src="assets/images/logo/pink-logo.png"-->
<!--                            alt="Logo image">-->
<!--                    </div>-->
<!--                </ng-container>-->
<!--                &lt;!&ndash; Navigation toggle button &ndash;&gt;-->
<!--              &lt;!&ndash;  <ng-container *ngIf="isScreenSmall">-->
<!--                    <button-->
<!--                        mat-icon-button-->
<!--                        (click)="toggleNavigation('mainNavigation')">-->
<!--                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>-->
<!--                    </button>-->
<!--                </ng-container>&ndash;&gt;-->
<!--                &lt;!&ndash; Components &ndash;&gt;-->
<!--               &lt;!&ndash; <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">-->
<!--                    <languages></languages>-->
<!--                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>-->
<!--                    <search [appearance]="'bar'"></search>-->
<!--                    <shortcuts></shortcuts>-->
<!--                    <messages></messages>-->
<!--                    <notifications></notifications>-->
<!--                    <user></user>-->
<!--                </div>&ndash;&gt;-->
<!--            </div>-->
            <!-- Bottom bar -->
     <!--       <ng-container *ngIf="!isScreenSmall">
                <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
                    <fuse-horizontal-navigation
                        [name]="'mainNavigation'"
                        [navigation]="navigation"></fuse-horizontal-navigation>
                </div>
            </ng-container>-->
        </div>
    </div>

    <!-- Content -->
    <div class=" relative   flex flex-auto justify-center w-full sm:px-8 sm:-mt-18 z-50">
        <div *ngIf="_profile.isFetched"
            class="flex flex-col flex-auto w-full sm:max-w-256 sm:shadow-xl sm:overflow-hidden bg-default rounded-2xl">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
 <!--   <div class="relative flex justify-center w-full z-49 print:hidden">
        <div class="flex items-center max-w-256 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
            <span class="font-medium text-secondary">-</span>
        </div>
    </div>-->

</div>
