import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToStr'
})
export class TimeToStrPipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {
      if(typeof value == 'string' ){
          value = Number(value);
      }
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return '(' + (hours ? hours + ":" : '') + minutes + " " + (!hours? 'ديققة' : 'ساعة') + ')';
  }

}
