import {Component, Input, OnInit} from '@angular/core';
import {BookingMeta, BookingService, ServiceItem} from "../../services/booking.service";
import {fuseAnimations} from "../../../../@fuse/animations";
import { ProfileService } from 'app/shared/services/profile.service';
import { Salon, SalonProfile } from 'app/api-models';

@Component({
    selector: 'app-reservation-footer',
    templateUrl: './reservation-footer.component.html',
    styleUrls: ['./reservation-footer.component.scss'],
    animations   : fuseAnimations
})
export class ReservationFooterComponent implements OnInit {

    @Input() view: number = 0;
    @Input() actionBtnText: string = 'متابعة الحجز';
    @Input() actionBtnGoTo: string = '';
    @Input() actionBtnDisabled: boolean = false;


    showMoreDesc: boolean = false;
    showShadow: boolean;
    showCartSum: boolean;
    selectedTab: number = 1;
    bookingMeta: BookingMeta;
    selectedServices: ServiceItem[];
    salonProfile: SalonProfile


    constructor(
        private _booking: BookingService,
        private _profile: ProfileService,
    ) {}

    ngOnInit(): void {
        this._profile.salonProfile$.subscribe(value => {
            this.salonProfile = value;
        })
        this._booking.bookingMeta$.subscribe(metas => {
            this.bookingMeta = metas
            this.selectedServices = this._booking.selectedServices
        })
    }
    anyAction($event: Event) {
        this.showShadow = !!($event.target as HTMLElement).scrollTop;
    }


    removeItem(service: ServiceItem) {
        const index = this.selectedServices.findIndex(e=>e.id === service.id);
        if (index > -1) {
            this._booking.addOrRemoveService(service.obj,false)
        }


    }
}
