<ng-container *ngIf="view === 0 && salonProfile?.salon?.canBook">
    <div #callActionRef [style.height.px]="callAction?.offsetHeight +12"></div>
    <div *ngIf="showCartSum" @fadeIn @fadeOut
         class="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10"></div>
    <div class="fixed bottom-0 bg-white z-50  mat-elevation-z12 overflow-hidden"
         [style.width.px]="callActionRef.offsetWidth">

        <div #callAction class="flex justify-between items-center py-3 px-2 "
             [class.mat-elevation-z6]="showShadow"
        >
            <div class="flex-grow flex items-center">
                <button mat-icon-button (click)="showCartSum = !showCartSum">
                    <mat-icon svgIcon="heroicons_outline:chevron-down"></mat-icon>
                </button>
                <div class="px-2">
                    <div><strong class="text-primary">{{selectedServices?.length}}</strong> <span> خدمات مختارة</span>
                    </div>
                    <div class="text-secondary"><span> التكلفة:</span><strong> {{bookingMeta?.sumPrice}} </strong>
                        <small>س.ر</small></div>
                </div>
            </div>
            <a [routerLink]="[actionBtnGoTo]" mat-raised-button color="primary" class="mx-3" [disabled]="actionBtnDisabled">
                {{actionBtnText}}
            </a>
        </div>

        <div #scrollablePnl class="px-4 max-h-64 overflow-y-auto" @expandCollapse *ngIf="showCartSum"
             (scroll)="anyAction($event)"
        >

            <mat-list>
                <ng-container *ngIf="!selectedServices?.length">
                    <div class="h-18 flex justify-center items-center text-secondary text-sm">لم يتم اختيار أي خدمة بعد</div>
                </ng-container>
                <ng-container *ngFor="let service of selectedServices">
                    <mat-list-item @expandCollapse>
                        <button mat-icon-button matListItemIcon class="mx-1" (click)="removeItem(service)">
                            <mat-icon color="primary" svgIcon="heroicons_outline:x-circle"></mat-icon>
                        </button>
                        <div class="flex justify-between">
                            <div class="font-bold">{{service.name}} </div>
                            <div class="text-sm"><strong> {{service.amount}} </strong> <small> ر.س </small></div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </div>

</ng-container>


<ng-container *ngIf="view === 1 && salonProfile?.salon?.canBook">
    <div #callActionRef1 [style.height.px]="callAction?.offsetHeight +12"></div>
    <div class="fixed bottom-0 bg-white z-50  mat-elevation-z12 overflow-hidden"
         [style.width.px]="callActionRef1.offsetWidth">

        <div #callAction class="flex justify-center items-center py-3 px-2 " >

            <a *ngIf="actionBtnGoTo"
                [routerLink]="[actionBtnGoTo]" mat-raised-button color="primary" class="mx-3">
                <span class="px-4">أحجز معنا الآن</span>
            </a>

            <button  *ngIf="!actionBtnGoTo" mat-raised-button color="primary" class="mx-3">
                <span class="px-4">أحجز معنا الآن</span>
            </button>
        </div>
    </div>
</ng-container>
