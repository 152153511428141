import { Component } from '@angular/core';
import {environment} from "../environments/environment";

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor()
    {
        console.log('ver', environment.appVersion)
        console.log('env', environment.env)
        console.log('env', environment.api_base_url)
    }
}
