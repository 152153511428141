import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
    name: 'myDate'
})
export class MyDatePipe implements PipeTransform {

    transform(value: string, outFormat = 'dd MMM yyyy', inputFormat = 'yyyy-MM-dd hh:mm a', ...args: unknown[]): unknown {
        // const dt = DateTime.local().reconfigure({ locale: "ar", numberingSystem: "latn" });
        if (!value)
            return;

        let date: DateTime
        if (inputFormat === 'yyyy-MM-dd hh:mm a') {
            date = DateTime.fromISO(value);
            if (!date.isValid) {
                date = DateTime.fromFormat(value, 'yyyy-MM-dd hh:mm a');
            }
        }else {
            date = DateTime.fromFormat(value, inputFormat);
        }

        // date = DateTime.fromFormat(value, 'yyyy-MM-dd hh:mm a');
        if (!date.isValid)
            return 'not valid';

        // const date1 = date.reconfigure({locale: "ar"});
        const localDate = date.setLocale("ar");

        return localDate.toFormat(outFormat);
    }

}
