import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import {ProfileService} from "./shared/services/profile.service";

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _profile: ProfileService,
        private _router: Router
/*        private _messagesService: MessagesService,
        private _navigationService: NavigationService,
        private _notificationsService: NotificationsService,
        private _quickChatService: QuickChatService,
        private _shortcutsService: ShortcutsService*/
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        const slug = route.firstChild.params.slug

    /*    this._profile.fetchSalonProfile1(slug).then(isSuccess => {
            if (!isSuccess) {
                this._router.navigate(['404'], {queryParams: {old: slug}})
            }
        });*/
        // Fork join multiple API endpoint calls to wait all of them to finish

        return forkJoin([
            this._profile.fetchSalonProfile(slug)
        /*    this._navigationService.get(),
            this._messagesService.getAll(),
            this._notificationsService.getAll(),
            this._quickChatService.getChats(),
            this._shortcutsService.getAll()*/
        ]);
    }
}
