import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TimeToStrPipe} from './pipes/time-to-str.pipe';
import {ReservationFooterComponent} from "./components/reservation-footer/reservation-footer.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {RouterLink} from "@angular/router";
import { ProviderServicesListComponent } from './components/provider-services-list/provider-services-list.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import {TimeOnlyPipe} from "./pipes/time-only.pipe";
import { MyDatePipe } from './pipes/my-date.pipe';
import {MatTabsModule} from "@angular/material/tabs";
import { VarDirective } from './directive/var.directive';
import { GetWorkingDaysPipe } from './pipes/get-working-days.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        RouterLink,
        MatExpansionModule,
        MatChipsModule,
        MatTabsModule,
        MatTooltipModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimeOnlyPipe,
        TimeToStrPipe,
        ReservationFooterComponent,
        ProviderServicesListComponent,
        MyDatePipe,
        GetWorkingDaysPipe,
        MatTooltipModule
    ],
    declarations: [
        TimeOnlyPipe,
        TimeToStrPipe,
        ReservationFooterComponent,
        ProviderServicesListComponent,
        MyDatePipe,
        VarDirective,
        GetWorkingDaysPipe
    ]
})
export class SharedModule {
}
