export const environment = {
    production: true,
    env: 'dev',
    api_base_url: 'https://system.pink-team.com/api-website',
    uploadsUrl: 'https://system.pink-team.com/uploads',
    base_url: 'https://web.pink-team.com/',

    pre_title: 'Pink | ',
    appVersion: require('../../package.json').version + '-STG',
    googleAnalyticsId: 'G-7V4KPZVQJJ', // Omer
    googleMapApiKey: 'AIzaSyDwgcTIKphfqyF9d_Ih8H9u2Z1gPtd0EfE',
    notificationTimeInterval: 30000,
};
